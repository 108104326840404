/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductCardApiModelDeliveryTime = {
    Unknown: 'Unknown',
    Direct: 'Direct',
    Delayed: 'Delayed',
    NoDelivery: 'NoDelivery',
    StoreOnly: 'StoreOnly'
} as const;
export type ProductCardApiModelDeliveryTime = typeof ProductCardApiModelDeliveryTime[keyof typeof ProductCardApiModelDeliveryTime];


export function ProductCardApiModelDeliveryTimeFromJSON(json: any): ProductCardApiModelDeliveryTime {
    return ProductCardApiModelDeliveryTimeFromJSONTyped(json, false);
}

export function ProductCardApiModelDeliveryTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCardApiModelDeliveryTime {
    return json as ProductCardApiModelDeliveryTime;
}

export function ProductCardApiModelDeliveryTimeToJSON(value?: ProductCardApiModelDeliveryTime | null): any {
    return value as any;
}

